import {ScorePanelScene} from '../scenes/score-panel-scene';
import {GameScene} from '../scenes/game-scene';
import {Button} from './button';
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import TWEEN_START = Phaser.Tweens.Events.TWEEN_START;

export default class GameComplete extends Phaser.GameObjects.Container {
    private mooi!: Phaser.GameObjects.Sprite;
    private opnieuw!: Button;
    private stoppen!: Button;
    public scene!: GameScene;
    private complete: Phaser.GameObjects.Sprite;

    constructor(scene: GameScene) {
        super(scene, 0, 0);

        this.x = scene.game.canvas.width / 2;
        this.y = scene.game.canvas.height / 4;

        this.complete = scene.make.sprite({x: -460, y: 0, key: 'sprite', frame: 'game/end/text-end-goed.png', origin: { x: 0, y: 0.5}, scale: 0}, true);
        this.add(this.complete);

        const tween = this.scene.tweens.add({targets: this.complete, scale: 1, duration: 500, ease: 'Bounce.Out', autoStart: true});
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete();
        });
        tween.on(TWEEN_START, () => {
            this.scene.audioManager.playFX('gamecomplete');
        });
    }

    showComplete(): void {
        const scoreDisplay = (this.scene.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        const x = 20;
        const y = 0;
        if (scoreDisplay.newHighScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-high.png'}, true);
        } else if (scoreDisplay.newScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-mooi.png'}, true);
        } else {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-leuk.png'}, true);
        }
        this.add(this.mooi);
        this.mooi
            .setScale(0)
            .setOrigin(0, 0.5);
        const tween = this.scene.tweens.add({targets: this.mooi, scale: 1, duration: 500, ease: 'Bounce.Out', autoStart: true, delay: 500});
        tween.on(TWEEN_START, () => {
            if (scoreDisplay.newHighScore) {
                 this.scene.audioManager.playFX('highscore');
            } else if (scoreDisplay.newScore) {
                this.scene.audioManager.playFX('highscore',.5);
            } else {
                this.scene.audioManager.playFX('highscore',.3);
            }
        });
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete2();
        });
    }

    showComplete2(): void {
        this.opnieuw = new Button(this.scene,-120, 75, () => { this.actionOnClickOpnieuw();}, 'sprite', 'game/end/btn-opnieuw-hover.png', 'game/end/btn-opnieuw.png', 'game/end/btn-opnieuw-hover.png');
        this.opnieuw.setScale(0);
        this.add(this.opnieuw);

        this.stoppen = new Button(this.scene, 170, 75, () => { this.actionOnClickStop(); },  'sprite', 'game/end/btn-stoppen-hover.png', 'game/end/btn-stoppen.png', 'game/end/btn-stoppen-hover.png');
        this.stoppen.setScale(0);
        this.add(this.stoppen);

        this.scene.tweens.add( { targets: this.opnieuw, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', paused: false, delay: 1200});
        this.scene.tweens.add( { targets: this.stoppen, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', paused: false, delay: 1500});
    }

    actionOnClickOpnieuw(): void {
        window.location.reload();
    }

    actionOnClickStop(): void {
        const href = window.document.getElementById('url-origin')?.getAttribute('href');
        window.location.href = href ?? '';
    }
}
