import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;

export default class ParticleDestroyWord extends Phaser.GameObjects.Group {

    //initialization code in the constructor
    private duration = 2000;
    private shrapnelConfig: ParticleEmitterConfig;
    private smokeConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private shrapnelEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private smokeEmitter: Phaser.GameObjects.Particles.ParticleEmitter;

    constructor(scene) {
        super(scene);

        this.shrapnelConfig = {
            frame: 'game/particles/stone.png',
            delay: {min: 0, max: 200},
            lifespan: this.duration,
            frequency: this.duration + 100, //this prevents running more than once
            scale: {start: 0.1, end: 0.7, ease: 'Expo.easeOut'},
            alpha: (p, k, t) => {
                return 1 - Math.abs(t);
            },
            quantity: 20,
            speed: {min: 100, max: 200},
            rotate: {min: -180, max: 180},
            angle: {min: -180, max: 0},
            gravityY: 200,
            on: false,

            // blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.smokeConfig = {
            frame: 'game/particles/smoke-puff-white.png',
            // delay: {min: 0, max: 200},
            lifespan: this.duration,
            frequency: this.duration + 100, //this prevents running more than once
            scale: (p, k, t) => {
                return Phaser.Math.Clamp(0.5 + t, 0, 1);
            },
            alpha: (p, k, t) => {
                return 0.7 - Math.abs(t);
            },
            quantity: 5,
            speed: [100, 200],
            rotate: {start: 0, end: 45},
            angle: {min: 180, max: 360},
            gravityY: -200,
            on: false,

            // blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.particles = this.scene.add.particles('sprite');
        this.add(this.particles);
        this.particles.setDepth(1);

        this.shrapnelEmitter = this.particles.createEmitter(this.shrapnelConfig);
        this.smokeEmitter = this.particles.createEmitter(this.smokeConfig);
    }

    fire(xPos, yPos) {
        this.shrapnelEmitter.setPosition(xPos, yPos);
        this.smokeEmitter.setPosition(xPos, yPos);

        this.shrapnelEmitter.start();
        this.smokeEmitter.start();

        setTimeout(() => {
            this.shrapnelEmitter.on = false;//.stop();
            this.smokeEmitter.on = false;//.stop();
        }, this.duration);
    }
}
