import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;
import {ParticleCorrectWordEvents} from '../common/events';

export default class ParticleCorrectWord extends Phaser.GameObjects.Group {

    //initialization code in the constructor
    private duration = 500;
    private flowConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private flowEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private currentLevel = 1;
    private targetX!: number;
    private targetY!: number;
    private follow: Phaser.GameObjects.Blitter;

    constructor(scene) {
        super(scene);

        this.flowConfig = {
            frame: ['game/particles/flare_point.png'],
            delay: {min: 0, max: 200},
            lifespan: this.duration,
            scale: {start: 0.5, end: 1, ease: 'Expo.easeOut'},
            alpha: {start: 0.8, end: 0.3, ease: 'Expo.easeIn'},
            sendToBack: true,
            quantity: 10,
            frequency: 10,
            speed: [10],
            rotate: {start: -90, end: 90},
            gravityY: 200,
            on: false,

            blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.particles = this.scene.add.particles('sprite');
        this.add(this.particles);
        this.particles.setDepth(1);

        this.flowEmitter = this.particles.createEmitter(this.flowConfig);

        this.follow = scene.add.blitter(0, 0, '').setVisible(false);
    }

    fire(xPos, yPos, level: number) {
        this.targetX = xPos;
        this.targetY = 934;

        this.currentLevel = level;
        this.flowEmitter.start();

        this.scene.tweens.add({targets: this.follow, x: {start: xPos, to: xPos}, y: {start: yPos, to: this.targetY}, duration: this.duration});

        this.flowEmitter.startFollow(this.follow);

        setTimeout(() => {
            this.flowEmitter.on = false;//.stop();
            this.moveComplete();
        }, this.duration);
    }

    moveComplete () {
        this.scene.events.emit(ParticleCorrectWordEvents.complete, this.targetX,  this.targetY, this.currentLevel);
    }
}
