/**
 * Game Over Object
 */

import {GameScene} from '../scenes/game-scene';
import {Button} from './button';

export default class GameOver extends Phaser.GameObjects.Container {
    public scene: GameScene;

    private opnieuw!: Button;
    private stoppen!: Button;
    private gameOver: Phaser.GameObjects.Sprite;
    constructor(scene: GameScene) {
        super(scene);

        this.scene = scene;

        this.x = scene.game.canvas.width / 2;
        this.y = scene.game.canvas.height / 4;

        this.gameOver = scene.make.sprite({x: 0, y: 0, key: 'sprite', frame: 'game/end/text-end-gameover.png', origin: {x: 0.5, y: 0.5}, scale: 0}, true);
        this.add(this.gameOver);

        scene.tweens.add({
            targets: this.gameOver, scaleX: 1, scaleY: 1, duration: 500, ease: 'Bounce.Out',
            onComplete: () => {
                this.showComplete();
            },
        });
    }

    showComplete() {
        this.opnieuw = new Button(this.scene,-120, 75, () => { this.actionOnClickOpnieuw();}, 'sprite', 'game/end/btn-opnieuw-hover.png', 'game/end/btn-opnieuw.png', 'game/end/btn-opnieuw-hover.png');
        this.opnieuw.setScale(0);
        this.add(this.opnieuw);

        this.stoppen = new Button(this.scene, 170, 75, () => { this.actionOnClickStop(); },  'sprite', 'game/end/btn-stoppen-hover.png', 'game/end/btn-stoppen.png', 'game/end/btn-stoppen-hover.png');
        this.stoppen.setScale(0);
        this.add(this.stoppen);

        this.scene.tweens.add( { targets: this.opnieuw, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', paused: false, delay: 700});
        this.scene.tweens.add( { targets: this.stoppen, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', paused: false, delay: 1000});
    }

    actionOnClickOpnieuw() {
        window.location.reload();
    }

    actionOnClickStop() {
        const href = window.document.getElementById('url-origin')?.getAttribute('href');
        window.location.href = href ?? '';
    }

}
