import Group = Phaser.GameObjects.Group;
import TypeTuinScene from '../scenes/type-tuin-scene';
import GroupConfig = Phaser.Types.GameObjects.Group.GroupConfig;
import TimelineBuilderConfig = Phaser.Types.Tweens.TimelineBuilderConfig;
import {WoordMachineEvents} from '../common/events';

export default class WoordMachine extends Phaser.GameObjects.Container {
    public wordLayer: Group;
    public scene: TypeTuinScene;
    private behindLayer: Phaser.GameObjects.Sprite;
    private frontLayer: Phaser.GameObjects.Sprite;
    private moveTrechterTween!: Phaser.Tweens.Tween;
    private blast: Phaser.GameObjects.Sprite;
    private railFront!: Phaser.GameObjects.Sprite;

    constructor(scene: TypeTuinScene, x = 0, y = 0) {
        super(scene, x, y);
        this.scene = scene;

        this.behindLayer = scene.make.sprite({
            key: 'sprite', frame: 'game/woordkanon-achter.png',
            origin: {x: 0, y: 0.5},
            x: 0, y: this.scene.game.canvas.height/2,
        }, true)
            .setAlpha(0);

        this.wordLayer = scene.make.group({} as GroupConfig);

        this.blast = this.scene.add.sprite(10,0,'sprite', 'game/blast.png');
        this.blast.alpha = 0;
        this.blast.setDepth(2); //need to do to ensure the word is below the frontlayer

        this.frontLayer = scene.make.sprite({
            key: 'sprite', frame: 'game/woordkanon-voor.png',
            origin: {x: 0, y: 0.5},
            x: 0, y: this.scene.game.canvas.height/2,
        }, true)
            .setAlpha(0);
        this.frontLayer.setDepth(3); //need to do to ensure the word is below the frontlayer

        this.railFront = scene.make.sprite({
            key: 'sprite', frame: 'game/rail-voor.png',
            origin: {x: 0, y: 0.5},
            x: 0, y: this.scene.game.canvas.height/2,
            depth: 4,
        }, true).setAlpha(0);
    }

    fadeIn(delay = 0) {
        this.scene.tweens.add({targets: [this.behindLayer, this.frontLayer], x: {from: '+=50', to: '-=50'},
            duration: 200, ease: 'Back.In', delay: delay});
        this.scene.tweens.add({targets: [this.behindLayer, this.frontLayer],  y: 500,
            duration: 200, ease: 'Back.Out', delay: delay});
        this.scene.tweens.add({targets: [this.behindLayer, this.frontLayer, this.railFront],  alpha: 1,
            duration: 200, ease: 'Linear', delay: delay});
    }

    moveTrechter() {
        const yPos = Phaser.Math.Between(360, 690);
        this.moveTrechterTween = this.scene.tweens.add({
            targets:  [this.behindLayer, this.frontLayer], y: yPos, delay: 200, duration: 500, ease: 'Back.Out',
            onComplete: () => {
                this.moveTrechterComplete();
            },
        });
    }

    moveTrechterComplete() {
        this.blast.setPosition(1100, this.frontLayer.y-180/2);

        this.scene.tweens.timeline({
            autoStart: true,
            ease: 'Linear',
            tweens: [
                {offset: 0, targets: [this.behindLayer, this.frontLayer], x: {from: '+=50', to: '-=50'}, duration: 200, ease: 'Back.In',
                onComplete: () => {
                    this.scene.audioManager.playFX('launch');
                    this.emit(WoordMachineEvents.makeWord, this, this.frontLayer.y);
                }},
                {offset: 300, targets: this.blast, scaleX: {start: 1}, scaleY: {from: 0, to: 1}, x: '-=400', duration: 500, ease: 'Sine.Out'},
                {offset: 350, targets: this.blast, alpha: {from: 0, to: 1}, duration: 200},
                {offset: 400, targets: this.blast, alpha: 0.5, scale: { from: 1, to: 1.5}, duration: 500, x: '-=200'},
                {offset: 900, targets: this.blast, alpha: 0, scale: 2.5, duration: 1000, x: '-=400', y: '-=100'},
            ],
        } as TimelineBuilderConfig);
    }
}
