/**
 * Plant object
 */
import {GameScene} from '../scenes/game-scene';
import {GardenPlantEvents} from '../common/events';

export default class GardenPlant extends Phaser.GameObjects.Sprite {
    public scene: GameScene;

    constructor(scene: GameScene, x, y, image, frame, level) {
        super(scene, x, y, image, frame);

        this.scene = scene;

        this.setScale(.1, .1);
        const newScale = Phaser.Math.RoundTo(Phaser.Math.RND.realInRange(.6, 1), -2);

        // less rotation on long plants
        if (level <= 2) {
            this.rotation = Phaser.Math.RoundTo(Phaser.Math.RND.realInRange(-.2, .2), -2);
        } else if (level == 3) {
            this.rotation = Phaser.Math.RoundTo(Phaser.Math.RND.realInRange(-.1, .1), -2);
        } else {
            this.rotation = Phaser.Math.RoundTo(Phaser.Math.RND.realInRange(-.05, .05), -2);
        }
        scene.audioManager.playFX('grow', .2);
        scene.tweens.add({
            targets: this, scaleX: newScale, scaleY: newScale, duration: 500,
            onComplete: () => {
                this.onCompleteGrow();
            },
        });
    }

    //Setup code, method called after preload
    onCompleteGrow() {
        this.emit(GardenPlantEvents.completeGrow);
    }


}
