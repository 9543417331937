import {Button, ButtonConfig} from './button';
import TextStyle = Phaser.GameObjects.TextStyle;
import TimelineBuilderConfig = Phaser.Types.Tweens.TimelineBuilderConfig;

const defaultTextStyle = {
    fontFamily: 'Londrina Solid',
    fontSize: '46px',
    color: '#c9e0b6',
} as TextStyle;

export default class Dashboard extends Phaser.GameObjects.Container {
    private levelPlate: Phaser.GameObjects.Sprite;
    private difficultyPlate: Phaser.GameObjects.Sprite;
    private difficultyIndicator: Phaser.GameObjects.Sprite;
    private scorePlate: Phaser.GameObjects.Sprite;
    private controlPlate: Phaser.GameObjects.Sprite;
    private buttonMute: Button;
    private buttonUnMute: Button;
    private scoreLabel: Phaser.GameObjects.Text;
    private buttonStop: Button;
    private scoreValue: Phaser.GameObjects.Text;
    private levelValue: Phaser.GameObjects.Text;
    private livesPlate: Phaser.GameObjects.Sprite;
    private livesLabel: Phaser.GameObjects.Sprite;
    private livesValue: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene, x = 0, y = 0) {
        super(scene, x, y);

        this.levelPlate = scene.add.sprite(110, 138, 'sprite', 'display/level.png')
            .setOrigin(0.5, 1)
            .setScale(1, 0);
        this.add(scene.add.existing(this.levelPlate));

        this.levelValue = scene.add.text(110, 116, '',
            Object.assign({}, defaultTextStyle, {color: '#d5f5ee'}),
        )
            .setOrigin(0.5, 1)
            .setScale(1, 0);
        this.add(this.levelValue);

        this.difficultyPlate = scene.add.sprite(406, 94, 'sprite', 'display/difficulty-display.png')
            .setOrigin(0.5, 0.5)
            .setScale(0, 0);
        this.add(scene.add.existing(this.difficultyPlate));

        this.difficultyIndicator = scene.add.sprite(406, 92, 'sprite', 'display/difficulty-wijzer.png')
            .setOrigin(0.5, 0.85)
            .setScale(0, 0);
        this.add(scene.add.existing(this.difficultyIndicator));

        this.scorePlate = scene.add.sprite(638, 94, 'sprite', 'display/score.png')
            .setOrigin(0.5, 0.5)
            .setScale(1, 0);
        this.add(scene.add.existing(this.scorePlate));

        this.scoreLabel = scene.add.text(546, 90, 'SCORE:',
            Object.assign({}, defaultTextStyle, {color: '#c9e0b6'}),
        ).setOrigin(0.5, 0.5);
        this.scoreLabel.setScale(1, 0);
        this.add(this.scoreLabel);

        this.scoreValue = scene.add.text(624, 90, scene.registry.get('score'),
            Object.assign({}, defaultTextStyle, {
                color: '#e2872a',
                stroke: '#ffffff',
                strokeThickness: 3,
            }),
        ).setOrigin(0, 0.5);
        this.scoreValue.setScale(1, 0);
        this.add(this.scoreValue);

        this.livesPlate = scene.add.sprite(918, 94, 'sprite', 'display/lives.png')
            .setOrigin(0.5, 0.5)
            .setScale(1, 0);
        this.add(scene.add.existing(this.livesPlate));

        this.livesLabel = scene.add.sprite(877, 92, 'sprite', 'display/lives-heart.png')
            .setOrigin(0.5, 0.5)
            .setScale(1, 0);
        this.add(scene.add.existing(this.livesLabel));

        this.livesValue = scene.add.text(932, 90, scene.registry.get('score'),
            Object.assign({}, defaultTextStyle, {
                color: '#fb204e',
                stroke: '#ffffff',
                strokeThickness: 3,
            }),
        ).setOrigin(0.5, 0.5);
        this.livesValue.setScale(1, 0);
        this.add(this.livesValue);

        this.controlPlate = scene.add.sprite(1131, 71, 'sprite', 'display/controls-bg.png')
            .setOrigin(0.5, 0.5)
            .setScale(1, 0);
        this.add(scene.add.existing(this.controlPlate));

        this.buttonMute = new Button(scene, 1113, 74, () => {
            this.toggleMute();
        }, 'sprite', 'display/btn-mute-hover.png', 'display/btn-mute.png', 'display/btn-mute-hover.png').setVisible(false);
        this.buttonMute.setAlpha(0);
        this.add(scene.add.existing(this.buttonMute));

        this.buttonUnMute = new Button(scene, 1113, 74, () => {
            this.toggleMute();
        }, 'sprite', 'display/btn-unmute-hover.png', 'display/btn-unmute.png', 'display/btn-unmute-hover.png', {
            overFramePosition: {x: 2, y: 0},
            downFramePosition: {x: 2, y: 0},
        } as ButtonConfig).setVisible(false);
        this.buttonUnMute.setAlpha(0);
        this.add(scene.add.existing(this.buttonUnMute));

        this.initToggleMute();

        this.buttonStop = new Button(scene, 1110, 110, () => {
            const href = window.document.getElementById('url-origin')?.getAttribute('href');
            window.location.href = href ?? '';
        }, 'sprite', 'display/btn-stop-hover.png', 'display/btn-stop.png', 'display/btn-stop-hover.png');
        this.buttonStop.setAlpha(0);
        this.add(scene.add.existing(this.buttonStop));
    }

    initToggleMute() {
        if (localStorage.getItem('mute') === '1') {
            this.buttonMute?.setVisible(true);
            this.buttonUnMute?.setVisible(false);
            this.scene.sound.mute = false;
        } else {
            this.buttonMute?.setVisible(false);
            this.buttonUnMute?.setVisible(true);
            this.scene.sound.mute = true;
        }
    }

    toggleMute() {
        if (localStorage.getItem('mute') === '1') {
            localStorage.setItem('mute', '0');
        } else {
            localStorage.setItem('mute', '1');
        }
        this.initToggleMute();
    }

    showTopControls() {
        this.scene.tweens.timeline({
            autoStart: true,
            ease: 'Bounce.Out',
            duration: 150,
            tweens: [
                {offset: 20, targets: [this.livesPlate, this.livesValue, this.livesLabel], ease: 'Linear', scaleY: 1},
                {offset: 80, targets: [this.scorePlate, this.scoreValue, this.scoreLabel], ease: 'Linear', scaleY: 1},
                {offset: 120, targets: [this.difficultyPlate, this.difficultyIndicator], ease: 'Linear', scale: 1},
                {offset: 160, targets: [this.levelPlate, this.levelValue], ease: 'Linear', scaleY: 1},
            ],
        } as TimelineBuilderConfig);

        // this.game.add.tween(this.scoreBG).to( { y:0 }, 300, Phaser.Easing.Power2.In, true);
        this.updateDifficulty();
    }

    initialAnimation() {
        this.scene.tweens.timeline({paused: false,
        tweens: [
            {offset: 0, targets: this.controlPlate, ease: 'Linear', scaleY: 1, duration: 150},
            {offset: 150, targets: [this.buttonMute, this.buttonUnMute, this.buttonStop], alpha: 1, duration: 100},
        ]});
    }

    updateDifficulty() {
        const diff = localStorage.getItem('difficulty');
        switch (diff) {
            case '1':
                this.difficultyIndicator?.setAngle(0);
                break
            case '2':
                this.difficultyIndicator?.setAngle(100);
                break
            default:
                this.difficultyIndicator?.setAngle(-100);
                break;
        }
    }


    updateScore(): void {
        this.scoreValue.setText(this.scene.registry.get('score'));
    }

    updateLevel(): void {
        this.levelValue.setText(`LEVEL ${this.scene.registry.get('level')}/5`);
    }

    updateLives(): void {
        this.livesValue.setText(this.scene.registry.get('lives'));
    }
}
