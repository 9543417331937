import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const NODE_ENV = '__NODE_ENV__';
const SENTRY_DSN = '__SENTRY_DSN__';
const SENTRY_LOG_LEVEL = '__SENTRY_LOG_LEVEL__';
const SENTRY_TRACES_SAMPLE_RATE = '__SENTRY_TRACES_SAMPLE_RATE__';

export function init() {
    if (SENTRY_DSN) {
        Sentry.init({
            environment: NODE_ENV,
            dsn: SENTRY_DSN,
            logLevel: parseInt(SENTRY_LOG_LEVEL),
            integrations: [new Integrations.BrowserTracing()],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: parseFloat(SENTRY_TRACES_SAMPLE_RATE),
        });
    }
}
