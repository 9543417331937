/**
 * Game scene of the game.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import Word from '../objects/word';
import {EnumDifficulty} from '../common/enums';
import {GameDefinitions} from '../types/gameDefinitions';
import WoordMachine from '../objects/woordMachine';
import LevelUp from '../objects/levelUp';
import GameComplete from '../objects/gameComplete';
import ParticleCorrectWord from '../objects/particleCorrectWord';
import TypeTuinScene from './type-tuin-scene';
import Plant from '../objects/plant';
import Garden from '../objects/garden';
import GameOver from '../objects/gameOver';
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;
import particleDestroyWord from '../objects/particleDestroyWord';
import ParticleDestroyWord from '../objects/particleDestroyWord';
import ANY_KEY_DOWN = Phaser.Input.Keyboard.Events.ANY_KEY_DOWN;
import ANY_KEY_UP = Phaser.Input.Keyboard.Events.ANY_KEY_UP;
import {ScorePanelScene} from './score-panel-scene';
import {GameEvents, GardenEvents, LevelUpEvents, ParticleCorrectWordEvents, WoordMachineEvents, WordStatusEvents} from '../common/events';

export class GameScene extends TypeTuinScene {

    private gameDefinition: GameDefinitions | undefined;
    private currentLevel = 1;//0;
    public difficulty: EnumDifficulty = EnumDifficulty.easy;
    private currentLevelWordsCorrect = 0;
    private currLetter = 0;
    private nexLevelTransition = false;
    private currentWord;
    private scoreDifficultyBonus = [0, 10, 20];
    private wordLayer!: Phaser.GameObjects.Group;
    private levelUp: LevelUp | undefined;
    private words: string[] = [];
    private livesRemaining!: number;
    private scoreIndexDifficulty = [1, 1.25, 1.5];
    private scoreIndexLevels = [1, 1.1, 1.2, 1.3, 1.4, 1.5];
    private woordMachine!: WoordMachine;
    private plant!: Plant;
    private garden!: Garden;
    private activeKeys = new Set();

    private particleCorrectWord!: ParticleCorrectWord;
    private particleDestroyWord!: ParticleDestroyWord;

    constructor() {
        super({key: 'GameScene'});
    }

    // region Phaser methods

    preload(): void {
        this.setCurrentLevel(this.registry.get('level') ?? this.currentLevel);
        this.gameDefinition = this.cache.json.get('gameDefinition') as GameDefinitions;
        this.difficulty = parseInt(localStorage.getItem('difficulty') ?? '0');
    }

    create(): void {
        super.create();

        // region Data
        this.livesRemaining = this.gameDefinition?.livesInit ?? 0;

        // endregion

        /*
         VISUALS::GENERAL
         */
        this.particleDestroyWord = new particleDestroyWord(this);
        this.add.existing(this.particleDestroyWord);
        this.particleCorrectWord = new ParticleCorrectWord(this);
        this.add.existing(this.particleCorrectWord);
        this.events.on(ParticleCorrectWordEvents.complete, (xPos, yPos, currentLevel) => {
            this.correctWordComplete(xPos, yPos, currentLevel);
        })

        this.woordMachine = new WoordMachine(this,0, 0);
        this.add.existing(this.woordMachine);
        this.woordMachine.on(WoordMachineEvents.makeWord, (element, yPosition) => {
            this.makeWordReal(element, yPosition);
        });
        this.wordLayer = this.woordMachine.wordLayer;

        this.plant = new Plant(this, -200, this.game.canvas.height).setDepth(5).setAlpha(0);
        this.add.existing(this.plant);
        this.time.addEvent({
            delay: 1000, callback: () => {
                this.plant.setAlpha(1);
                this.plant.initMove();
            },
        });

        this.garden = new Garden(this);
        this.garden.on(GardenEvents.growWordComplete, () => {this.gardenGrowComplete();});
        this.add.existing(this.garden);

        this.levelUp = new LevelUp(this);
        this.levelUp.y = (this.sys.canvas.height / 2) - 50;
        this.levelUp.x = this.sys.canvas.width / 2;
        this.levelUp?.on(LevelUpEvents.complete, () => {
            this.checkLevelTransitionComplete();
        });
        this.add.existing(this.levelUp);

        /*
         INIT
         */

        this.words = this.gameDefinition?.words[this.difficulty] || [];

        this.woordMachine.fadeIn(2500);

        this.time.addEvent({
            delay: 4000, callback: () => {
                this.initGame();
            },
        });
    }

    update() {
        this.plant.updateExternal();
    }

    // endregion

    // region custom methods

    initGame() {
        this.prepareWordMachine();
    }

    wordsToComplete(): number | undefined {
        if (this.gameDefinition !== undefined) {
            return this.gameDefinition.levels[this.currentLevel - 1].wordsToComplete;
        }
    }

    secondsPerWord() {
        if (this.gameDefinition !== undefined) {
            return this.gameDefinition.levels[this.currentLevel - 1].secondsPerWord;
        }
        return 0;
    }

    nextLevel() {
        if (this.currentLevel === this.gameDefinition?.levels.length) {
            this.nexLevelTransition = true;
            this.completeGame();
        } else {
            this.setCurrentLevel(this.currentLevel+1);
            this.nexLevelTransition = true;
            this.audioManager.levelUpdate(this.currentLevel);
            this.registry.set('lives', this.livesRemaining + 1);
            this.levelUp?.show();
            this.currentLevelWordsCorrect = 0;
        }
        this.plant.mouthBrul();
    }

    setCurrentLevel(level: number): void {
        this.currentLevel = level;
        this.game.registry.values.level = this.currentLevel;
        this.events.emit(GameEvents.levelChange);
    }

    prepareWordMachine() {
        this.woordMachine?.moveTrechter();
    }

    makeWordReal(elem: Element, yPos: number) {
        const index = Math.floor(Math.random() * this.words.length);
        const word = this.words[index];
        this.words.splice(index, 1);
        this.currLetter = 0;
        this.currentWord = new Word(this, this.game.canvas.width-50, yPos-180/2, word, 1000 * this.secondsPerWord());

        // Capture all key presses
        this.input.keyboard.on(ANY_KEY_DOWN, (char) => {
            if (!this.activeKeys.has(char.code)) {
                this.activeKeys.add(char.code);
                this.keyPress(char);
            }
        });
        this.input.keyboard.on(ANY_KEY_UP, (char) => {
            this.activeKeys.delete(char.code);
        });
        this.currentWord.on(WordStatusEvents.change, (word, status) => {
            this.wordProgress(word, status);
        });
        const w = this.add.existing(this.currentWord);
        this.wordLayer?.add(w);
    }

    wordProgress(word, progressStatus) {
        // KLAAR (dus af)
        this.plant.updateBehaviour(progressStatus, this.currentWord);

        if (progressStatus == 'complete') {
            if (this.plant.status !== 'normal') {
                this.wordEnd();
            }
        }

        // KLAAR (dus goed)
        if (progressStatus == 'correctComplete') {
            // uitfaden & particles: compleet: nieuw woord
            this.particleCorrectWord.fire(this.currentWord.x, this.currentWord.y, this.currentLevel);

            this.currentWord?.destroy();
            if (this.currentLevelWordsCorrect === this.wordsToComplete()) {
                this.nextLevel();
            }
        }
    }

    //
    // decreaseScoreIndexWord(): void {
    //     this.registry.values.indexWord--;
    //     this.wordManager?.updateScore(this.calcScore());
    // }
    //
    // resetScoreIndexWord(): void {
    //     this.registry.values.indexWord = 5;
    //     this.wordManager?.updateScore(this.calcScore());
    // }

    // calcScore(): number {
    //     return (this.registry.values.indexWord * 10) + this.scoreDifficultyBonus[this.difficulty];
    // }

    keyPress(char): void {
        this.audioManager.playFX('tick', .8);
        if (char.key === this.currentWord.text[this.currLetter]) {
            this.currLetter++;
            this.currentWord.updateColor(this.currLetter);
            if (this.currLetter == this.currentWord.text.length) {
                this.wordCorrect();
            }
        } else {
            this.wordWrong();
        }
    }

    incrementScore() {
        // 100-0 punten afhanklijk van de voortgang, level en moeilijkheidsgraad
        const score = Math.round((this.currentWord?.scoreValue ?? 0) * this.scoreIndexDifficulty[this.difficulty] * this.scoreIndexLevels[this.currentLevel - 1]);
        this.registry.set('score', this.registry.get('score') + score);
        this.events.emit(GameEvents.scoreChange);
    }

    wordCorrect() {
        // score
        this.endkeyboardCallbacks();
        this.incrementScore();

        this.currentLevelWordsCorrect++;
        this.currentWord?.onCorrect();
        this.audioManager.playFX('wordok');
    }

    wordWrong() {
        this.audioManager.playFX('wordwrong');
        this.destroyWord();
    }

    wordEnd() {
        //todo nodig? wordt in plant zelf al afgespeeld
        this.audioManager.playFX('bite');
        setTimeout(() => {
            this.destroyWord();
        }, 300)
        // this.destroyWord();
    }

    destroyWord() {
        this.livesRemaining--;
        this.endkeyboardCallbacks();

        this.registry.set('lives', this.livesRemaining);
        this.events.emit(GameEvents.livesChange);

        this.currentWord?.destroy();
        this.particleDestroyWord.fire(this.currentWord.x, this.currentWord.y);

        if (this.livesRemaining == 0) {
            this.endGame();
        } else {
            this.time.addEvent({
                delay: 2000, callback: () => {
                    this.prepareWordMachine();
                },
            });
        }
    }

    correctWordComplete(xPos, yPos, level) {
        this.garden.growWord(xPos, yPos, level);
    }

    checkLevelTransitionComplete() {
        this.nexLevelTransition = false;
        this.checkNextWord();
    }

    checkNextWord() {
        // makeword
        if (!this.nexLevelTransition) {
            this.prepareWordMachine();
        }
    }

    gardenGrowComplete() {
        // pas na plant is gegroeid
        this.checkNextWord();
    }

    endGame() {
        this.plant.randomMoveEnd(0);

        // todo
        // this.game.scoreDisplay.addScore(this.game.score);
        // this.game.scoreDisplay.displayFinalScore();
        const scoreDisplay = (this.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        scoreDisplay.setTotalScore();
        scoreDisplay.displayFinalScore();

        this.audioManager.gameOver();
        const gameover = new GameOver(this);
        this.add.existing(gameover);

        this.endGameRemove();
    }

    completeGame() {
        this.plant.randomMoveEnd(0);

        const bonusScore = this.addBonusScore();
        const scoreDisplay = (this.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        scoreDisplay.setTotalScore();

        const style = {
            font: '28px Londrina Solid', fill: '#ffffff', align: 'center',
            stroke: '#e79609', strokeThickness: 6, lineSpacing: -20,
        } as TextStyle;

        const bonusScoreTxt = this.make.text({x: 650, y: 377, text: bonusScore + ' bonuspunten!', style, origin: 0.5, scale: 0}, true);
        this.tweens.add({
            targets: bonusScoreTxt,
            scaleX: 1,
            scaleY: 1,
            duration: 500,
            ease: 'Bounce.Out',
            delay: 2500,
        });

        scoreDisplay.displayFinalScore();

        const gamecomplete = new GameComplete(this);
        this.add.existing(gamecomplete);
        this.endGameRemove();

        this.audioManager.gameComplete();
    }

    addBonusScore() {
        const bonusScore = this.livesRemaining * 25;

        this.registry.set('score', this.registry.get('score') + bonusScore);
        this.events.emit(GameEvents.scoreChange);

        return bonusScore;
    }

    endGameRemove() {
        this.currentWord?.destroy();
        this.endkeyboardCallbacks();
    }

    endkeyboardCallbacks() {
        this.input.keyboard.off('keydown');
        // const keyboard = this.game.input.keyboard;
        // keyboard.re.onDownCallback = keyboard.onUpCallback = keyboard.onPressCallback = null;
    }

    // endregion
    // private updateScore() {
    //   this.sco
    // }

    private updateLives() {
        this.livesRemaining = this.game.registry.get('lives');
    }

    private updateLevel() {
        this.currentLevel = this.game.registry.get('level');
    }
}
