import TextStyle = Phaser.GameObjects.TextStyle;
import Tween = Phaser.Tweens.Tween;
import {GameScene} from '../scenes/game-scene';
import TWEEN_UPDATE = Phaser.Tweens.Events.TWEEN_UPDATE;
import {WordStatusEvents} from '../common/events';

const statusList = ['started','toTarget','headBackward','mouthOpen'];

export default class Word extends Phaser.GameObjects.Container {
    public scoreValue = 0;
    public text: string;

    private targetX = 0;
    private targetY = 0;
    private duration = 0;
    private scoreValueBase = 0;
    private correctWord: Phaser.GameObjects.Text;
    private characterCrop: number[];
    private statusList: string[] = [];
    private statusCurrent = 0;
    private moveWord: Tween | undefined;
    private remainingMS = 0;
    public scene: GameScene;
    private previousPercentage = -1;

    static get statusList() {
        return statusList;
    }

    constructor(scene: GameScene, x = 0, y = 0, word: string, duration: number) {
        super(scene, x, y);
        this.scene = scene;
        const style = {
            fontFamily: 'Londrina Solid',
            fontSize: '50px',
            color: '#382003',
            align: 'center',
            stroke: '#ffffff',
            strokeThickness: 3,
        } as TextStyle;

        this.add(scene.add.text(0, 0, word, style).setOrigin(0.5, 0.5));
        this.correctWord = scene.add.text(0, 0, word, Object.assign({}, style, {color: '#2bb62e'}))
            .setOrigin(0.5, 0.5);
        this.correctWord.setCrop(0, 0, 0, this.correctWord.height);
        this.add(this.correctWord);

        this.characterCrop = this.getCharacterCrop(word, style);
        this.text = this.correctWord.text;

        // set as props
        this.duration = duration;
        this.scoreValueBase = 10 * word.length;
        this.scoreValue = this.scoreValueBase;
        this.moveStart(duration);
        this.statusList = Word.statusList;
        this.statusCurrent = 0;
    }

    moveStart(duration){
        this.previousPercentage = -1;
        const startX = this.x;
        this.targetX = 450;
        this.targetY = Phaser.Math.RND.integerInRange(350, 550);
        this.moveWord = this.scene.tweens.add({ targets: this, x: {from: this.x, to: this.targetX, ease: 'Linear'}, y: { from: this.y, to: this.targetY, ease: 'Quad.In'} , duration: duration});
        this.moveWord.on('complete', () => {
            this.onEnd();
        });
        this.moveWord.on(TWEEN_UPDATE, (tween, key, target, current) => {
            if (key === 'x') {
                const perc = ((current - startX) / (target.targetX - startX));
                const wholePerc =Math.floor(perc * 100);
                if (wholePerc !== this.previousPercentage ) {
                    this.onUpdate(tween, perc);
                }
                this.previousPercentage = wholePerc;
            }
        });
    }

    onCorrect(): void {
        if (this.moveWord) {
            this.scene.tweens.remove(this.moveWord);
        }
        this.scene.tweens.timeline({})
            .add({targets:this, scaleX:1.5, scaleY:1.5, duration: 300, ease: 'Elastic.In', offset: 0})
            .add({targets: this, y: '+=15', duration: 500, ease: 'Linear', offset: 600})
            .add({ targets:this, scaleX:.1, scaleY:.1, duration: 200, ease: 'Linear', offset: 800,
            onComplete: () => {this.emit(WordStatusEvents.change, this, 'correctComplete')}}).play();

        this.emit(WordStatusEvents.change, this, 'correctStart');
    }

    onUpdate(twn: Tween, percent) {
        // score purpose only
        this.scoreValue = Math.round(this.scoreValueBase - (percent * this.scoreValueBase));

        if (this.remainingMS < 1500 && this.statusCurrent < 1) {
            this.statusCurrent++;
            this.emit(WordStatusEvents.change, this, this.statusList[this.statusCurrent]); // toTarget
        } else if (percent > 0.93 && this.statusCurrent  < 2) {
            this.statusCurrent++;
            this.emit(WordStatusEvents.change, this, this.statusList[this.statusCurrent]); // headBackward
        } else if (percent > 0.95 && this.statusCurrent  < 3) {
            this.statusCurrent++;
            this.emit(WordStatusEvents.change, this, this.statusList[this.statusCurrent]); // mouthOpen
        }
    }

    onEnd(){
        this.emit(WordStatusEvents.change, this, 'complete', 10,  this.remainingMS);
    }

    /**
     * Unveils the correct part of the word by setting a cropping to the character position.
     * Phaser 2 had the possibility to set the color of single characters in a text object, but Phaser 3
     * does not have this option.
     *
     * @param pos
     */
    updateColor(pos: number): void {
        this.correctWord.setCrop(0, 0, this.characterCrop[pos - 1], this.correctWord.height);
    }

    /**
     * In this helper function the width of each character of the word is calculated
     * and placed in the characterCrop array.
     * The array contains cumulative values, so it can be easily used to set the cropping
     * in the updateColor function.
     *
     * @param word
     * @param style
     */
    private getCharacterCrop(word: string, style: TextStyle): number[] {
        const chWidths: number[] = [];
        const txt = this.scene.make.text({
            style: style,
        }, false)

        let width = 0;// txt.style.baselineX;
        let txtPart = '';
        for (const character of word) {
            txtPart += character;
            txt.setText(txtPart);
            txt.updateText();
            width = txt.width - txt.style.baselineX * 2
            chWidths.push(width);
        }
        return chWidths;
    }

    destroy(){
        this.scene.tweens.add({targets:this, scaleX:0, scaleY:0, duration: 300, ease: 'Linear',
            onComplete: () => {
                this.emit(WordStatusEvents.change, this, 'destroy', this.remainingMS);

                this.correctWord.destroy(true);

                super.destroy();
            }});
    }
}
