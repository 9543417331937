/**
 * Garden object
 */
import GardenPlant from './gardenPlant';
import {GameScene} from '../scenes/game-scene';
import {GardenEvents} from '../common/events';

export default class Garden extends Phaser.GameObjects.Container {
    public scene: GameScene;

    constructor(scene) {
        super(scene);
        this.scene = scene;
    }

    growWord(xPos, yPos, level) {
        const c = Phaser.Math.RND.integerInRange(1, 3);
        const plant = new GardenPlant(this.scene, xPos, yPos, 'sprite', 'game/planten/' + (level) + '-' + c + '.png', level);
        plant.setOrigin(0.5, 1);
        const newPlant = this.scene.add.existing(plant);
        this.add(newPlant);
        this.emit(GardenEvents.growWordComplete);
    }
}
