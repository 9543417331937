/**
 * Plant
 */
import {GameScene} from '../scenes/game-scene';
import GameObject = Phaser.GameObjects.GameObject;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

const HalfPi = 3.14159265359/2;
const segmentLength = 430;

export default class Plant extends Phaser.GameObjects.Container {

    public scene: GameScene;

    private shadow: Phaser.GameObjects.Sprite;
    private elemBottom: Phaser.GameObjects.Sprite;
    private elemTop: Phaser.GameObjects.Sprite;
    private between: Phaser.Geom.Point;
    private elemWiel: Phaser.GameObjects.Sprite;
    private elemPot: Phaser.GameObjects.Container;
    private armBehind: Phaser.GameObjects.Sprite;
    private elemPotChild: Phaser.GameObjects.Sprite;
    private armFront: Phaser.GameObjects.Sprite;
    private movePlant!: Phaser.Tweens.Tween;
    private moveHead!: Phaser.Tweens.Tween;
    public status!: string;
    private head: SpineGameObject;
    private gameEnded = false;

    constructor(scene: GameScene, x, y) {
        super(scene, x, y);

        this.scene = scene;

        this.shadow = scene.add.sprite(this.x, this.y, 'sprite', 'game/plant/schaduw.png');
        this.shadow.setOrigin(0.5, 1);
        this.add(this.shadow);

        this.elemBottom = scene.add.sprite(5, -this.height - 170, 'sprite', 'game/plant/body-lower.png');
        this.elemBottom.setOrigin(0.5, 1);
        this.add(this.elemBottom);

        this.elemTop = scene.add.sprite(-20, -350, 'sprite', 'game/plant/body-upper.png');
        this.elemTop.setOrigin(0.5, -0.15);
        this.add(this.elemTop);

        this.between = new Phaser.Geom.Point(0, 0);

        this.armBehind = scene.add.sprite(-5, -152, 'sprite', 'game/plant/arm.png')
        this.armBehind.setOrigin(0, 1);
        this.add(this.armBehind);

        this.head = scene.add.spine(-8,-150, 'kop', 'normaal_knipper', true);
        this.head.setSkinByName('kop_lleesetende_plant');
        this.head.setDepth(3);
        this.head.preMultipliedAlpha = true;
        this.add(this.head as unknown as GameObject);

        const anim = this.head.play('normaal', true);
        anim.on('complete', (a) => {
           if (a.animation.name === 'brul') {
                   this.mouthNormal();
           } else if (a.animation.name === 'bite') {
               this.status = 'bite';
               this.mouthNormal();
               this.randomMove(1000);
           }
        });

        this.mouthNormal();

        this.elemWiel = scene.add.sprite(0, 0, 'sprite', 'game/plant/wiel.png');
        this.elemWiel.setOrigin(0.5, 0.5);
        this.elemWiel.setPosition(0, - this.elemWiel.height / 2);
        this.add(this.elemWiel);

        this.elemPotChild = scene.add.sprite(0, 0, 'sprite', 'game/plant/pot.png');
        this.elemPotChild.setOrigin(0.5);

        this.armFront = scene.add.sprite(25, 4, 'sprite', 'game/plant/arm.png');
        this.armFront.setOrigin(0, 1);

        this.elemPot = scene.add.container(-38, -138, [this.elemPotChild, this.armFront]);
        this.elemPot.rotation = .3;
        this.add(this.elemPot);

    }

    initMove() {
        const newPos = Phaser.Math.RND.integerInRange(450, 650);

        this.mouthBrul();
        this.movePlant = this.scene.tweens.add({ targets: this, x: newPos, duration: 2000, ease: 'Back.Out',
            onUpdate: () => {this.onMove();},
            onComplete: () => {this.randomMove();},
        });
    }

    randomMove(delayTween = 0) {
        if (delayTween === undefined) {
            delayTween = Phaser.Math.RND.integerInRange(1000, 3000);
        }

        const newPos = Phaser.Math.RND.integerInRange(100, 450);

        if (this.movePlant) {
            this.movePlant.removeAllListeners('complete');
            // this.scene.tweens.remove(this.movePlant);
        }

        // stop when the game is ready
        if (this.gameEnded) {
            return;
        }

        this.movePlant = this.scene.tweens.add({ targets: this, x: newPos, delay: delayTween, duration: 2000, ease: 'Back.Out',
            onUpdate: () => {this.onMove();},
            onComplete: () => {
                this.randomMove();
            },
        });
    }

    randomMoveEnd(delayTween?: number, recurse= false) {
        if (delayTween === undefined) {
            delayTween = Phaser.Math.RND.integerInRange(0, 3000);
        }

        if (!recurse) {
            // stop the randomMove function
            this.gameEnded = true;
        }

        const newPos = Phaser.Math.RND.integerInRange(250, 850);

        if (this.movePlant) {
            this.movePlant.removeAllListeners('complete');
        }

        this.movePlant = this.scene.tweens.add({ targets: this, x: newPos, delay: delayTween, duration: 2000, ease: 'Back.Out',
            onUpdate: () => {this.onMove();},
            onComplete: () => {this.randomMoveEnd(undefined, true);},
        });
    }

    /*
     source:
     https://www.khanacademy.org/computer-programming/inverse-kinematics/1191743453
     */
    drawArm(endEffectorX, endEffectorY, preferredRotation) {
        let dirx = endEffectorX - this.elemBottom.x;
        let diry = endEffectorY - this.elemBottom.y;
        const len = Math.sqrt(dirx * dirx + diry * diry);
        dirx = dirx / len;
        diry = diry / len;

        let disc = segmentLength * segmentLength - len * len / 4;

        this.between.x = this.elemBottom.x + dirx * len / 2;
        this.between.y = this.elemBottom.y + diry * len / 2;
        disc = Math.sqrt(disc);
        if (preferredRotation < 0) {
            disc = -disc; // Make it a negative number
        }
        this.between.x -= diry * disc;
        this.between.y += dirx * disc;
    }

    onMove() {
        // todo : kunnen we hier de overgangen subtieler maken?
        //  nu wordt progress gebruikt, maar eigenlijk moet dit ook nog opbouwend en afnemend zijn,
        //  zodat er meer vloeiende beweging komt. en als het wiel stilstaat moet de hoek ook weer
        //  0 zijn.
        if ((this.movePlant.data[0].end ?? 0) < (this.movePlant.data[0].start ?? 0)) {
            this.elemPot.rotation = this.movePlant.progress * .2;
        } else {
            this.elemPot.rotation = this.movePlant.progress * -.2;
        }
        this.armFront.rotation = (this.elemPot.rotation * -1.15) + .3;
        this.armBehind.rotation = (this.elemPot.rotation * -1) + .1;
        this.head.rotation = this.elemPot.rotation * -.35;
    }

    // EVENT-0
    mouthBrul() {
        this.head.play('brul');
        this.scene.audioManager.playFX('monster');
    }

    mouthNormal() {
        this.moveHead = this.scene.tweens.add({ targets: this.elemTop, x: -10, y: -380, duration: 800, ease: 'Linear'});

        // add a simple randomness between normaal and knipper
        const seconds = new Date().getSeconds();
        if (seconds % 4 === 0) {
            this.head.play('normaal', true);
        } else {
            this.head.play('normaal_knipper', true);
        }
        this.status = 'normal';
    }

    // EVENT-1
    moveToTarget(xPos) {
        if (this.movePlant) {
            this.movePlant.removeAllListeners(TWEEN_COMPLETE);
        }

        this.movePlant = this.scene.tweens.add({ targets: this, x: xPos - 150, duration: 1300, ease: 'Back.Out',
            onUpdate: () => {this.onMove();},
        });
        this.status = 'totarget';
    }

    // EVENT-2
    moveHeadBackward(xPos, yPos) {
        this.scene.tweens.remove(this.movePlant);
        this.scene.tweens.remove(this.moveHead);
        this.scene.tweens.killTweensOf(this);

        this.moveHead = this.scene.tweens.add({ targets: this.elemTop, x: -270, y: yPos-20, duration: 150, ease: 'Linear'});
    }

    // EVENT-3
    moveMouthOpen(xPos, yPos) {
        this.head.play('open');

        this.scene.tweens.remove(this.movePlant);
        this.scene.tweens.remove(this.moveHead);

        this.moveHead = this.scene.tweens.add({ targets: this.elemTop, x: xPos , y: yPos-20 , duration: 500, ease: 'Back.In'});
        this.status = 'open';
    }

    // EVENT-4
    mouthBite(xPos) {
        this.head.setMix('open', 'bite', 0.2);
        this.head.play('bite');
        this.scene.time.addEvent({
            delay: 430, callback: () => {
                this.scene.tweens.add({
                    targets: this, x: xPos - 400, duration: 900, ease: 'Quad.Out',
                    onUpdate: () => {
                        this.onMove();
                    },
                });
                this.moveHead = this.scene.tweens.add({ targets: this.elemTop, x: -10, y: -380, duration: 500, ease: 'Back.Out'});
            },
        });
    }

    updateBehaviour(progressStatus, word) {
        const xPos = (word.targetX - this.x);
        const yPos = (word.targetY - this.y);

        if (progressStatus == 'destroy') {
            // setTimeout(() => {this.mouthNormal();}, 2000);
        } else if (progressStatus === 'correctStart') {
            this.mouthNormal();
            // this.randomMove(1000);
        } else if (progressStatus === 'toTarget' && this.status === 'normal') {
            this.moveToTarget(word.targetX);
        } else if (progressStatus === 'complete') {
            if (this.status !== 'bite') {
                this.mouthBite(word.targetX);
            }
        } else if (progressStatus === 'headBackward') {
            this.moveHeadBackward(xPos, yPos);
        } else if (progressStatus === 'mouthOpen') {
            this.moveMouthOpen(xPos, yPos);
        }
    }

    updateExternal() {
        if (this.elemWiel.rotation !== this.x / 75) {
            this.elemWiel.rotation = this.x / 75;
        }

        this.head.x = this.elemTop.x - 90;
        this.head.y = this.elemTop.y;
        this.drawArm(this.elemTop.x, this.elemTop.y, -1);
        const n = Phaser.Math.Angle.BetweenPoints(this.between, this.elemTop) + HalfPi;
        const n2 = Phaser.Math.Angle.BetweenPoints(this.between, this.elemBottom) - HalfPi;

        if (this.elemTop.rotation !== n) {
            this.elemTop.rotation = n;
        }
        if (this.elemBottom.rotation !== n2) {
            this.elemBottom.rotation = n2;
        }
        this.head.update();
    }
}
